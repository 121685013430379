import React, { useState, useEffect } from 'react';
import { Table, Tag, Spin } from 'antd';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import './PingResults.scss';

function PingResults() {
  const [pingData, setPingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.aurora-connect.com/ping-results');
        if (!response.ok) {
          throw new Error(`Сервер ответил со статусом ${response.status}`);
        }

        const result = await response.json();

        if (result && result.monitors) {
          const sortedData = result.monitors.sort((a, b) => {
            if (a.uptime_status === 'down' && b.uptime_status !== 'down') {
              return -1;
            }
            if (a.uptime_status !== 'down' && b.uptime_status === 'down') {
              return 1;
            }
            return 0;
          });

          setPingData(sortedData);
        } else {
          throw new Error('Непредвиденный формат ответа');
        }
      } catch (error) {
        console.error('Ошибка при получении данных:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const columns = [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ссылка',
      dataIndex: 'target',
      key: 'target',
      render: (text) => <a href={text} target='_blank' rel='noopener noreferrer'>{text}</a>,
    },
    {
      title: 'Статус',
      dataIndex: 'uptime_status',
      key: 'uptime_status',
      render: (status) => (
        <Tag color={status === 'up' ? 'green' : 'red'}>
          {status === 'up' ? <IoIosArrowUp /> : <IoIosArrowDown />}
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Последняя проверка',
      dataIndex: 'last_check',
      key: 'last_check',
      render: (timestamp) => new Date(timestamp * 1000).toLocaleString(),
    },
  ];

  return (
    <div className="ping-results-container">
      <h1 className="mb-4">Результаты Ping</h1>
      {isLoading ? (
        <div className="loading-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={pingData}
          rowKey="id"
          pagination={false}
          rowClassName={(record) => (record.uptime_status === 'down' ? 'down-row' : '')}
        />
      )}
    </div>
  );
}

export default PingResults;
