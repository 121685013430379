import React from "react";
import { Layout } from "antd";
import { useAuth } from "./Context/AuthContext";
import HeaderMenu from "./Components/HeaderMenu/HeaderMenu";
import PingResults from "./Components/PingResults/PingResults";

const { Content } = Layout;

const Home = () => {
  const { user, logout } = useAuth();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <HeaderMenu logout={logout} />
      <Layout style={{ marginLeft: 260 }}> {/* Отступ для бокового меню */}
        <Content style={{ padding: '20px 50px', maxWidth: 1200, margin: '0 auto' }}>
          <div className="site-layout-content">
            {user ? (
              <PingResults />
            ) : (
              <h3>Авторизируйтесь</h3>
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Home;
