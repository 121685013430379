import React, { useState, useEffect } from "react";
import { Layout, Button, Table, Spin, message, Modal, Input } from "antd";
import axios from "axios";
import HeaderMenu from "../HeaderMenu/HeaderMenu";

const { Content } = Layout;

const APIURL = "https://api.aurora-connect.com/";
const API_KEY = process.env.REACT_APP_API_KEY;

const axiosInstance = axios.create({
    headers: {
        Authorization: `Basic ${btoa(API_KEY)}`,
    },
});

const Domains = () => {
    const [domains, setDomains] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [newDomains, setNewDomains] = useState("");
    const [editDomain, setEditDomain] = useState({ name: "", url: "" });
    const [loadingAdd, setLoadingAdd] = useState(false);

    const loadDomains = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get(`${APIURL}domains`);
            if (response.status === 200) {
                setDomains(response.data.sort((a, b) => new Date(a.expiration_date) - new Date(b.expiration_date)));
            } else {
                message.error("Ошибка загрузки доменов.");
            }
        } catch (error) {
            console.error("Ошибка при загрузке доменов:", error);
            message.error("Ошибка при загрузке доменов.");
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteDomain = async (url) => {
        try {
            await axiosInstance.delete(`${APIURL}domains/${url}`);
            message.success("Домен удален!");
            loadDomains();
        } catch (error) {
            message.error("Ошибка при удалении домена.");
        }
    };

    const handleUpdateExpiration = async () => {
        try {
            await axiosInstance.post(`${APIURL}domains/update-expiration`);
            message.success("Данные истечения обновлены!");
            loadDomains();
        } catch (error) {
            message.error("Ошибка при обновлении данных истечения.");
        }
    };

    const handleAddDomains = async () => {
        try {
            setLoadingAdd(true);
            const domainList = newDomains.split("\n").map(url => url.trim()).filter(url => url).map(url => ({ url }));
            if (domainList.length === 0) {
                message.error("Введите хотя бы один домен.");
                setLoadingAdd(false);
                return;
            }
            await axiosInstance.post(`${APIURL}domains/bulk-add`, { domains: domainList });
            message.success("Домены добавлены!");
            setNewDomains("");
            setModalVisible(false);
            loadDomains();
        } catch (error) {
            message.error("Ошибка при добавлении доменов.");
        } finally {
            setLoadingAdd(false);
        }
    };

    const handleEditDomain = async () => {
        try {
            await axiosInstance.put(`${APIURL}domains/${editDomain.url}`, editDomain);
            message.success("Домен обновлен!");
            setEditMode(false);
            loadDomains();
        } catch (error) {
            message.error("Ошибка при редактировании домена.");
        }
    };

    const openEditModal = (domain) => {
        setEditDomain({ ...domain });
        setEditMode(true);
    };

    useEffect(() => {
        loadDomains();
    }, []);

    const columns = [
        { title: "Название", dataIndex: "name", key: "name", render: (text) => text || "Без имени" },
        { title: "Домен", dataIndex: "url", key: "url" },
        { title: "Дата истечения", dataIndex: "expiration_date", key: "expiration_date", sorter: (a, b) => new Date(a.expiration_date) - new Date(b.expiration_date) },
        { title: "Действия", key: "actions", render: (_, record) => (
            <>
                <Button onClick={() => openEditModal(record)} style={{ marginRight: 8 }}>Редактировать</Button>
                <Button onClick={() => handleDeleteDomain(record.url)} danger>Удалить</Button>
            </>
        ) }
    ];

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <HeaderMenu />
            <Content style={{ padding: "50px" }}>
                <h1>Управление Доменами</h1>
                <Button type="primary" onClick={() => setModalVisible(true)} style={{ marginBottom: "16px", marginRight: "10px" }}>Добавить домены</Button>
                <Button onClick={handleUpdateExpiration} style={{ marginBottom: "16px" }}>Обновить данные истечения</Button>
                <Table dataSource={domains} columns={columns} rowKey="url" loading={loading} />
                <Modal title="Добавить домены" open={modalVisible} onCancel={() => setModalVisible(false)} onOk={handleAddDomains} confirmLoading={loadingAdd}>
                    <p>Введите список доменов (по одному на строку):</p>
                    <Input.TextArea rows={5} value={newDomains} onChange={(e) => setNewDomains(e.target.value)} />
                </Modal>
                <Modal title="Редактировать домен" open={editMode} onCancel={() => setEditMode(false)} onOk={handleEditDomain}>
                    <Input placeholder="Название" value={editDomain.name} onChange={(e) => setEditDomain({ ...editDomain, name: e.target.value })} style={{ marginBottom: 8 }} />
                    <Input placeholder="URL" value={editDomain.url} onChange={(e) => setEditDomain({ ...editDomain, url: e.target.value })} style={{ marginBottom: 8 }} />
                </Modal>
            </Content>
        </Layout>
    );
};

export default Domains;