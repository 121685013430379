import React, { useState, useEffect } from "react";
import { Layout, Select, Button, Spin, message } from "antd";
import axios from "axios";
import HeaderMenu from "../HeaderMenu/HeaderMenu";

const { Content } = Layout;
const { Option } = Select;

const APIURL = "https://api.aurora-connect.com/";
const apiCreds = process.env.REACT_APP_API_KEY;

const TLS = () => {
    const [brands, setBrands] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState("");
    const [loadingBrands, setLoadingBrands] = useState(false);
    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState(1);
    const [tlsState, setTlsState] = useState("on");

    useEffect(() => {
        if (project) loadBrands(project);
    }, [project]);

    const loadBrands = async (project) => {
        try {
            setLoadingBrands(true);
            const authString = `Basic ${btoa(apiCreds)}`;
            const response = await axios.get(`${APIURL}brands?project=${project}`, {
                headers: {
                    Authorization: authString,
                },
            });

            if (response.status === 200) {
                setBrands(response.data.brands || []);
            } else {
                console.error(`Ошибка загрузки брендов: ${response.statusText}`);
                message.error("Ошибка загрузки брендов.");
            }
        } catch (error) {
            console.error("Ошибка при загрузке брендов:", error);
            message.error("Ошибка при загрузке брендов.");
        } finally {
            setLoadingBrands(false);
        }
    };

    const updateTls13 = async (applyToAll) => {
        if (loading) return;

        try {
            setLoading(true);
            const authString = `Basic ${btoa(apiCreds)}`;
            const response = await axios.post(
                `${APIURL}tls13-settings-all`,
                {
                    value: tlsState,
                    project: applyToAll ? null : project,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: authString,
                    },
                }
            );

            if (response.status === 200) {
                message.success("Настройки TLS 1.3 успешно обновлены");
            } else {
                message.error("Ошибка обновления настроек TLS 1.3.");
            }
        } catch (error) {
            console.error("Ошибка при обновлении настроек TLS 1.3:", error);
            message.error("Ошибка при обновлении настроек TLS 1.3.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <HeaderMenu />
            <Content style={{ padding: "50px" }}>
                <h1>Управление TLS 1.3</h1>
                <Select
                    value={project}
                    onChange={(value) => setProject(value)}
                    style={{ width: "100%", marginBottom: "16px" }}
                >
                    <Option value={1}>Проект 1</Option>
                    <Option value={2}>Проект 2</Option>
                    <Option value={3}>Проект 3</Option>
                </Select>

                <Select
                    value={tlsState}
                    onChange={(value) => setTlsState(value)}
                    style={{ width: "100%", marginBottom: "16px" }}
                >
                    <Option value="on">Включить TLS 1.3</Option>
                    <Option value="off">Выключить TLS 1.3</Option>
                </Select>

                <Button
                    type="primary"
                    onClick={() => updateTls13(true)}
                    disabled={loading}
                    style={{ width: "100%", marginBottom: "16px" }}
                >
                    {loading ? <Spin /> : "Применить для всех брендов"}
                </Button>

                <Button
                    onClick={() => updateTls13(false)}
                    disabled={loading}
                    style={{ width: "100%" }}
                >
                    {loading ? <Spin /> : "Применить для текущего проекта"}
                </Button>
            </Content>
        </Layout>
    );
};

export default TLS;
