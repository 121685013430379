import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  BiCloud, BiCog, BiLogOut, BiHome, BiEnvelope
} from "react-icons/bi"; // Иконки из react-icons
import Logo from "./logo.svg";
import "./HeaderMenu.scss";

const { Sider } = Layout;

const Header = ({ logout }) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation(); // Хук для получения текущего маршрута

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const menuItems = [
    {
      key: "/",
      icon: <BiHome />,
      label: <Link to="/">Главная</Link>,
    },
    {
      key: "cloudflare",
      icon: <BiCloud />,
      label: "Cloudflare",
      children: [
        { key: "/add-cloud", label: <Link to="/add-cloud">Добавить Cloudflare</Link> },
        { key: "/dns-update", label: <Link to="/dns-update">Обновить DNS</Link> },
        { key: "/purge", label: <Link to="/purge">Сбросить кэш</Link> },
        { key: "/add-domain", label: <Link to="/add-domain">Добавить домен</Link> },
        { key: "/add-back", label: <Link to="/add-back">Добавить бек</Link> },
        { key: "/add-front", label: <Link to="/add-front">Добавить фронт + почтовые настройки</Link> },
        { key: "/tls", label: <Link to="/tls">TLS</Link> },
      ],
    },
    {
      key: "links",
      icon: <BiCog />,
      label: "Ссылки",
      children: [
        { key: "/links", label: <Link to="/links">Все ссылки</Link> },
        { key: "/domains", label: <Link to="/domains">Домены</Link> },
      ],
    },
    {
      key: "/smtp",
      icon: <BiEnvelope />,
      label: <Link to="/smtp">SMTP Checker</Link>,
    },
    {
      key: "/logout",
      icon: <BiLogOut />,
      label: <span onClick={logout}>Выйти</span>,
    },
  ];

  // Получаем активные ключи на основе текущего маршрута
  const getSelectedKeys = () => {
    const path = location.pathname;
    return [path];
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={toggleCollapse}
      theme="dark"
      width={260}
      className="vertical-menu-sider"
    >
      <div className="logo-container">
        <img src={Logo} alt="Logo" className="logo" />
      </div>
      <Menu
        mode="inline"
        theme="dark"
        items={menuItems}
        defaultOpenKeys={['cloudflare', 'links']}
        selectedKeys={getSelectedKeys()} // Устанавливаем активные ключи
      />
    </Sider>
  );
};

export default Header;
